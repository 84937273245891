import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import profilePic from "../images/profilepic.jpg"

class Sidebar extends React.Component {
  state = {
    email: null,
  }

  _handleChange = e => {
    console.log({
      [`${e.target.name}`]: e.target.value,
    })
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  _handleSubmit = e => {
    e.preventDefault()

    console.log("submit", this.state)

    addToMailchimp(this.state.email, this.state)
      .then(({ msg, result }) => {
        console.log("msg", `${result}: ${msg}`)

        if (result !== "success") {
          throw msg
        }
        alert(msg)
      })
      .catch(err => {
        console.log("err", err)
        alert(err)
      })
  }

  render() {
    return (
      <div className="sidebar">
        <img
          src={profilePic}
          alt="profile pic"
          style={{
            float: "center",
            marginBottom: 0,
            maxWidth: "200px",
          }}
        />{" "}
        <br />
        <p>A blog 4 me</p>
        <p>Never miss a post</p>
        <form onSubmit={this._handleSubmit}>
          <input
            className="email-input"
            type="email"
            placeholder="EMAIL ADDRESS"
            onChange={this._handleChange}
            name="email"
          />
          <button className="btn btn-cta" type="submit">
            Subscribe
          </button>
        </form>
      </div>
    )
  }
}

export default Sidebar
