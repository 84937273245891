import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import PrimaryButtonStyledLink from "../components/Link"
import typography from "../utils/typography"
import Sidebar from "../components/Sidebar"

const { rhythm, scale } = typography

export default ({ data }) => {
  return (
    <Layout>
      <div className="content">
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div className="tile" key={node.id}>
            <h2 style={{ margin: "0px" }}>{node.frontmatter.title}</h2>
            <p className="postmeta">
              {node.frontmatter.category}
              {node.frontmatter.date}
            </p>
            <p>{node.frontmatter.description}</p>
            <PrimaryButtonStyledLink
              linkText="Read More"
              to={node.fields.slug}
            />
            <br />
            <hr />
          </div>
        ))}
      </div>
      <Sidebar />
    </Layout>
  )
}
export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
