import React from "react"
import { Link } from "gatsby"

const PrimaryButtonStyledLink = props => (
  <Link className="btn primary-button" to={props.to}>
    {props.linkText}
  </Link>
)

export default PrimaryButtonStyledLink
